import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/performance_measurement.jpg'
import performance_measurement from '../../images/pages/performance_measurement.png'


const pageTitle = "Performance Measurement";
const pageSlug = "performance-measurement";


class PerformanceMeasurement extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Performance measurements are the heartbeat of your organization. You can do it, if you can measure it!</h6>
        </div>
</section>

<section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>Performance Measurement's primary goal is to measure, interact, manage and improve the overall performance of your company.</h3>
                  <span className="break"></span>
                  <h5>Service </h5>
                  <p>Monera Technologies will help you match your business activities with strategic business goals. We use cutting-edge methods to provide you with the best possible measurements.</p>
                  <p>Performance measures help your organization to obtain information on the right decision by answering complicated questions such as:</p>
                  <ul>
                    <li>Are we measuring the right things?</li>
                    <li>Are our measurements proactive?</li>
                    <li>Are our processes being improved?</li>
                  </ul>
                  <h5>Quality Measurement </h5>
                  <p>Develop a culture that adopts principles of performance management to ensure quality. Through offering practical solutions, our experts will help you make this a reality.</p>
                  <h5>Metric Scorecard & Performance </h5>
                  <p>Report Measurement and assessment is key to a productive organization. Our experts help develop documents and accurate scorecards that communicate information clearly to key stakeholders.</p>
                  <h5>Performance Measurement Services </h5>
                  <p>We provide customers with specific solutions, in our performance measurement services summary, read more about each capability.</p>
                  <h5>Closed-Loop Performance Analysis </h5>
                  <p>A closed-loop analysis of performance will transform measurements into behavior. Providing a solution that can guide the process of making your decisions.</p>
                  <h5>Business strategy alignment with quality drivers </h5>
                  <p>An active performance management program should make the organization's mission and vision the central focus. We help you match your strategic goals with key drivers of quality.</p>
           
                  <span className="break"></span>
                  <h3>Enable your company to create value by giving it accurate predictions that are backed up by reliable information as quickly as possible.</h3>
                  <img src={performance_measurement} />

                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default PerformanceMeasurement
